import keyMirror from 'keymirror';

// --------------------
// SHARED
// --------------------

const CONTENT_TYPE = {
  UNKNOWN_TYPE: 'unknown',
  ALL_ARTICLES: 'article',
  EDITORIAL: 'post_editorial',
  HOUSEWARM: 'post_housewarm',
  POST: 'post_picture',
  PICTURE: 'picture_item',
  COMMENT: 'comment',
  OFFER: 'shopping_offer',
  USER: 'user',
  NOTIFICATION: 'notification'
};

const POST_FORMATS = {
  SHORTFORM: 'shortform',
  HOUSETOUR: 'house_tour',
  KNOWHOW: 'know_how'
};

const USER_DATA = globalThis?.CURRENT_USER_DATA
  ? globalThis?.CURRENT_USER_DATA
  : globalThis?.PUBLIC_PROFILE_DATA;

const HELMET_CONTEXT = {};

const DT_FORMAT = 'D MMM YY, h:mm a';
const NEW_FOR_MONTHS = 3;

const ALLOW_FILE_FORMATS = ['jpg', 'jpeg', 'bmp', 'png', 'gif', 'heic'];
const ALLOW_FILE_ATTRIBS = ALLOW_FILE_FORMATS.map((i) => `.${i}`);
const MAX_FILE_SIZE = 25 * 1000000; // 25MB
const MAX_FILES = 30;

const FILE_REJECTIONS = {
  FILE_TOO_LARGE: 'file-too-large',
  TOO_MANY_FILES: 'too-many-files',
  FILE_INVALID_TYPE: 'file-invalid-type'
};

const changePositionType = keyMirror({
  UP: null,
  DOWN: null
});

const LIKE_API = '/likes';
const BOOKMARKS_API = '/bookmarks';
const DRAFT_POSTS_API = '/draft_posts';
const COMPLAINTS_API = '/complaints';

const ARTICLE_ACTION_TYPE = 'Post';

const selectorKeys = keyMirror({
  LIKE_TYPE: null,
  ARTICLE_ID: null,
  ARTICLE_SHARED_COUNT: null,
  SORT_QUERY: null,
  ARTICLE_TYPE: null,
  ARTICLE_LIKED: null,
  ARTICLE_SAVED: null,
  ARTICLE_TITLE: null,
  ARTICLE_OWNER: null,
  KEY_TOPIC_TAG: null,
  ARTICLE_HANDLE: null,
  ARTICLE_COUNTRY: null,
  POST_SET_UPDATE: null,
  OTHER_TOPIC_TAGS: null,
  PAGINATING_QUERY: null,
  PAGINATION_RESET: null,
  ARTICLE_BODY_HTML: null,
  ARTICLE_BODY_JSON: null,
  ARTICLE_LIKE_COUNT: null,
  ARTICLE_SAVE_COUNT: null,
  ARTICLE_COMMENT_COUNT: null,
  ARTICLE_VIEW_COUNT: null,
  ARTICLE_TOPIC_TAGS: null,
  SEO_ARTICLE_DETAIL: null,
  ENRICHED_TOPIC_TAGS: null,
  ARTICLE_COVER_IMAGE: null,
  ARTICLE_PUBLISHED_AT: null,
  ARTICLES_LIST_UPDATE: null,
  LIST_POSTS_SET_UPDATE: null,
  ARTICLE_DISPLAY_PROFILE: null,
  PROFILE_POSTS_SET_UPDATE: null,
  PROFILE_POSTS_SET_SAVE_UPDATE: null,
  TRENDING_USERS_UPDATE: null
});

const DEFAULT_SLATE_VALUE = [
  {
    type: 'p',
    children: [
      {
        text: ''
      }
    ]
  }
];

const BLOG_INDEX_NAME = 'Ideas';
const BLOG_INDEX_PATH = '/ideas';

const ARTICLE_INDEX_NAME = 'Articles';
const ARTICLE_INDEX_PATH = `${BLOG_INDEX_PATH}/article`;

const EDITORIAL_INDEX_ITEM = 'Decor Guide';
const EDITORIAL_INDEX_NAME = `${EDITORIAL_INDEX_ITEM}s`;
const EDITORIAL_INDEX_PATH = `${BLOG_INDEX_PATH}/decor-guide`;

const HOUSEWARM_INDEX_ITEM = 'Home Tour';
const HOUSEWARM_INDEX_NAME = `${HOUSEWARM_INDEX_ITEM}s`;
const HOUSEWARM_INDEX_PATH = `${BLOG_INDEX_PATH}/housewarming`;
const HOUSEWARM_NEW_PATH = `${HOUSEWARM_INDEX_PATH}/new`;

const PICTURES_INDEX_ITEM = 'Post';
const PICTURES_INDEX_NAME = `${PICTURES_INDEX_ITEM}s`;
const PICTURES_INDEX_PATH = BLOG_INDEX_PATH;
const PICTURES_NEW_PATH = `${PICTURES_INDEX_PATH}/new`;

const CONTESTS_INDEX_ITEM = 'Contest';
const CONTESTS_INDEX_NAME = `${CONTESTS_INDEX_ITEM}s`;
const CONTESTS_INDEX_PATH = `${BLOG_INDEX_PATH}/contests`;

const HASHTAG_INDEX_ITEM = 'Hashtag';
const HASHTAG_INDEX_NAME = `${HASHTAG_INDEX_ITEM}s`;
const HASHTAG_INDEX_PATH = `${BLOG_INDEX_PATH}/tag`;

const OFFER_INDEX_ITEM = 'Offer';
const OFFER_INDEX_NAME = `${OFFER_INDEX_ITEM}s`;
const OFFER_INDEX_PATH = '/offers';

const POST_EDITORIAL_KEY = CONTENT_TYPE?.EDITORIAL;
const POST_HOUSEWARM_KEY = CONTENT_TYPE?.HOUSEWARM;
const SET_POST_PICTURES_KEY = CONTENT_TYPE?.POST;
const POST_PICTURES_KEY = CONTENT_TYPE?.PICTURE;

const CONTENT_TYPE_LINK_DATA = {
  [`${CONTENT_TYPE?.EDITORIAL}`]: {
    label: EDITORIAL_INDEX_ITEM,
    pathPrefix: ARTICLE_INDEX_PATH
  },
  [`${CONTENT_TYPE?.HOUSEWARM}`]: {
    label: HOUSEWARM_INDEX_ITEM,
    pathPrefix: ARTICLE_INDEX_PATH
  },
  [`${CONTENT_TYPE?.POST}`]: {
    label: PICTURES_INDEX_ITEM,
    pathPrefix: PICTURES_INDEX_PATH
  },
  [`${CONTENT_TYPE?.PICTURE}`]: {
    label: 'Picture',
    pathPrefix: null
  },
  [`${CONTENT_TYPE?.OFFER}`]: {
    label: 'Product',
    pathPrefix: null
  },
  [CONTENT_TYPE?.UNKNOWN_TYPE]: {
    label: CONTENT_TYPE?.UNKNOWN_TYPE,
    pathPrefix: '/nil'
  }
};

const contentSortFeatured = {
  key: 'featured',
  label: 'Featured',
  heading: 'Featured'
};

const contentSortPopular = {
  key: 'popularity_score',
  label: 'Popular',
  heading: 'Popular'
};

const contentSortRecent = {
  key: 'created_at',
  label: 'Recent',
  heading: 'Recent'
};

const contentSortHouseTours = {
  key: 'house_tours',
  label: 'House Tours',
  heading: 'House Tours',
  hideForRecent: true
};

const contentSortFollowing = {
  key: 'following',
  label: 'Following',
  heading: 'Following',
  hideForRecent: true
};

const contentSortProfessional = {
  key: 'professional',
  label: 'Professional',
  heading: 'Professional',
  hideForRecent: true
};

const contentSortOptions = [
  contentSortRecent,
  contentSortPopular,
  contentSortFeatured,
  contentSortFollowing,
  contentSortHouseTours,
  contentSortProfessional
];

const contentSortOrder = keyMirror({
  asc: null,
  desc: null
});

const sortValueFirstVisit = {
  key: 'POST_SORT_VALUE_FIRST_VISIT',
  value: contentSortPopular?.key
};

const sortValueAfterPostCreated = {
  key: 'POST_SORT_VALUE_AFTER_POST_CREATED',
  value: contentSortRecent?.key
};

// --------------------
// CONTESTS
// --------------------

const CONTESTS_API = '/contests';

const CONTESTS = 'CONTESTS';
const CONTESTS_LIST = `${CONTESTS}_LIST`;

const contestsKeys = {
  SEARCH_PARAMS: `${CONTESTS}_SEARCH_PARAMS`,
  FILTER_PARAMS: `${CONTESTS}_FILTER_PARAMS`,
  SORT_PARAMS: `${CONTESTS}_SORT_PARAMS`,
  PAGE_PARAMS: `${CONTESTS}_PAGE_PARAMS`,
  SEARCH_PARAMS_DEFAULT: `${CONTESTS}_SEARCH_PARAMS_DEFAULT`,
  FILTER_PARAMS_DEFAULT: `${CONTESTS}_FILTER_PARAMS_DEFAULT`,
  SORT_PARAMS_DEFAULT: `${CONTESTS}_SORT_PARAMS_DEFAULT`,
  PAGE_PARAMS_DEFAULT: `${CONTESTS}_PAGE_PARAMS_DEFAULT`,
  IS_PAGINATING: `${CONTESTS}_IS_PAGINATING`,
  PAGE_INFO: `${CONTESTS}_PAGE_INFO`,
  CONTEST_DETAIL: `${CONTESTS}_DETAIL`,
  CONTEST_ENTRIES: `${CONTESTS}_ENTRIES`,
  PREVIOUS_CONTEST_MATCHED_POSTS: `PREVIOUS_${CONTESTS}_MATCHED_POSTS`,
  CONTEST_ENTRIES_UPDATE: `${CONTESTS}_ENTRIES_UPDATE`,
  CONTEST_BREADCRUMB: `${CONTESTS}_BREADCRUMB`,
  CUSTOM_FIELDS_PARAMS: `${CONTESTS}_CUSTOM_FIELDS_PARAMS`
};

const contestListKeys = {
  SEARCH_PARAMS: `${CONTESTS_LIST}_SEARCH_PARAMS`,
  FILTER_PARAMS: `${CONTESTS_LIST}_FILTER_PARAMS`,
  SORT_PARAMS: `${CONTESTS_LIST}_SORT_PARAMS`,
  PAGE_PARAMS: `${CONTESTS_LIST}_PAGE_PARAMS`,
  SEARCH_PARAMS_DEFAULT: `${CONTESTS_LIST}_SEARCH_PARAMS_DEFAULT`,
  FILTER_PARAMS_DEFAULT: `${CONTESTS_LIST}_FILTER_PARAMS_DEFAULT`,
  SORT_PARAMS_DEFAULT: `${CONTESTS_LIST}_SORT_PARAMS_DEFAULT`,
  PAGE_PARAMS_DEFAULT: `${CONTESTS_LIST}_PAGE_PARAMS_DEFAULT`,
  IS_PAGINATING: `${CONTESTS_LIST}_IS_PAGINATING`,
  PAGE_INFO: `${CONTESTS_LIST}_PAGE_INFO`,
  CONTESTS_ITEMS: `${CONTESTS_LIST}_ITEMS`,
  CONTESTS_ITEMS_STATUS: `${CONTESTS_LIST}_ITEMS_STATUS`,
  CONTESTS_ITEMS_UPDATE: `${CONTESTS_LIST}_ITEMS_UPDATE`,
  CONTESTS_LIST_BREADCRUMB: `${CONTESTS_LIST}_BREADCRUMB`
};

const blogRoutesKeys = keyMirror({
  BLOG_ROUTES_DATA: null
});

const CONTEST_LIST_GRID = { m: 1, t: 2, d: 2, place: 8 };

const CONTEST_STATUS = {
  ACTIVE: 'active',
  UPCOMING: 'upcoming',
  ENDED: 'ended'
};

// --------------------
// POST::PICTURE
// --------------------

const PICTURES = 'PICTURES';
const POSTS_SET = 'POSTS_SET';
const POST_CUSTOM_FIELDS_API = '/custom_fields';
const PICTURES_POST_API = '/post_types/pictures_posts';
const PROFILE_PICTURES_INDEX_API = `profiles/${USER_DATA?.auth_user_id}/pictures_posts`;
const POSTS_SET_API = `${BLOG_INDEX_PATH}/pictures_posts`;
const HOME_TOURS_API = '/posts';
const FOLLOWING_API = '/posts/following';
const HOME_PROFESSIONAL_API = '/posts/home_professional';
const RESET_FOLLOWING_POSTS_COUNT_API = '/notifications/mark_all_read_by_type';
const FOLLOWING_POST_NEW_TYPE = 'following_post_new';
const HASHTAG_SUGGESTIONS_API = '/autocompletes/tags';
const USERS_SUGGEST_FOLLOWEES = '/users/suggest_followees';
const USERS_PRIVATE_SUGGEST_FOLLOWEES = '/users/private_suggest_followees';
const USERS_DISMISS_FOLLOWEES = '/users/dismiss_followees';
const DISMISSED_USER_IDS_KEY = 'dismissed_ids';
const SUGGEST_FOLLOWEES_LIMIT_PAGE = 10;

const MOB_GUTTER = '1.5em';
const MOB_COLUMNS_COUNT = 2;
const MOB_SCROLL_THRESHOLD = 0.6;
const DESK_SCROLL_THRESHOLD = 0.7;
const INDEX_CONTENT_PER_ROW = 4;
const POSTS_LIST_GRID = { m: 2, t: 3, d: 4, place: 12 };
const HOME_POST_GRID = { m: 2, t: 4, d: 4, place: INDEX_CONTENT_PER_ROW };

const CUSTOM_FIELDS = {
  FLOORAREA: {
    id: '628caed3549dd92a3bcd570b',
    icon: 'ic-idea-floorplan'
  },
  BEDROOMS: {
    id: '627cdc9f549dd933fceaa2ba',
    icon: 'ic-cats-beds'
  },
  HOUSEHOLD: {
    id: '627cdc9f549dd933fceaa2b8',
    icon: 'ic-idea-household'
  },
  SIZE: {
    id: '627cdc9f549dd933fceaa2b9',
    icon: 'ic-idea-housing'
  },
  STYLE: {
    id: '627cdc9f549dd933fceaa2bb',
    icon: 'ic-idea-decorate'
  }
};

const picturesKeys = keyMirror({
  PICTURE_SET: null,
  IS_PICTURES_SET_RESTORED: null,
  FRAMER_PORTAL_OPEN: null,
  FRAMER_PORTAL_AUTO_OPEN: null,
  FRAMER_ACTIVE_PIC_ID: null,
  UNSAVED_POST: null,
  PICTURE_SETTINGS: null,
  PICTURE_SET_SETTINGS: null,
  PICTURE_SET_AUTOCOMPLETE_VALUES: null,
  PICTURES_SET_CONTENT_TAGS: null,
  PICTURES_SET_DESCRIPTION: null,
  PICTURES_SET_TITLE: null,
  PICTURES_SET_IMAGES: null,
  PICTURES_REPLACE_IMAGES: null,
  DELETE_SET_IMAGE: null,
  IMAGE_SET_POSITION: null,
  PICTURES_SET_CUSTOM_FIELDS: null,
  SELECTOR_CUSTOM_FIELD_SET_POST_PICTURES: null,
  PICTURES_SET_POST_CUSTOM_FIELDS: null,
  SELECTOR_CUSTOM_FIELD_POST_IMAGE: null,
  SET_POST_CUSTOM_FIELDS: null,
  SET_POST_CUSTOM_FIELDS_VALIDATION: null,
  SET_SHOPPING_PIN: null,
  UPDATE_SHOPPING_PIN: null,
  DELETE_SHOPPING_PIN: null,
  SET_SORTED_IMAGES: null,
  ADD_SET_IMAGE: null,
  POSITIONS_MODAL_OPEN: false,
  IS_TOUCHED_FORM: null,
  REQUIRED_CUSTOM_FIELDS: null,
  DEFAULT_PICTURE_CUSTOM_FIELDS: null,
  SHOW_ARCHIVE_WARNINGS: null,
  PICTURES_FORM_ERRORS: null,
  ADD_PICTURES_FORM_ERRORS: null,
  REMOVE_PICTURES_FORM_ERRORS: null,
  PICTURES_ADD_IMAGE_PINS: null,
  PICTURES_CHANGE_IMAGE_PIN: null,
  PICTURES_DELETE_IMAGE_PIN: null,
  FIRST_PICTURE_AR: null,
  IS_UPLOADING: null,
  IS_MEDIA_LOADED: null,
  MEDIA_LOADED_IDS: null,
  SUBMISSION_MODE: null
});

const listPostsSetKeys = {
  SEARCH_PARAMS: `${POSTS_SET}_SEARCH_PARAMS`,
  FILTER_PARAMS: `${POSTS_SET}_FILTER_PARAMS`,
  SORT_PARAMS: `${POSTS_SET}_SORT_PARAMS`,
  PAGE_PARAMS: `${POSTS_SET}_PAGE_PARAMS`,
  SEARCH_PARAMS_DEFAULT: `${POSTS_SET}_SEARCH_PARAMS_DEFAULT`,
  FILTER_PARAMS_DEFAULT: `${POSTS_SET}_FILTER_PARAMS_DEFAULT`,
  SORT_PARAMS_DEFAULT: `${POSTS_SET}_SORT_PARAMS_DEFAULT`,
  PAGE_PARAMS_DEFAULT: `${POSTS_SET}_PAGE_PARAMS_DEFAULT`,
  IS_PAGINATING: `${POSTS_SET}_IS_PAGINATING`,
  PAGE_INFO: `${POSTS_SET}_PAGE_INFO`,
  LIST_POSTS_SET: `${POSTS_SET}_LIST`,
  UPDATE_LIST_POSTS_SET_ITEM: `UPDATE_LIST_${POSTS_SET}_ITEM`,
  PROFILE_POSTS_SET: `${POSTS_SET}_PROFILE`,
  POSTS_SET_IDS: `${POSTS_SET}_IDS`,
  POST_SET: `${POSTS_SET}_ITEM`,
  NEW_POST_DATA: `${POSTS_SET}_NEW_POST_DATA`,
  POST_SET_SAVED: `${POSTS_SET}_SAVED_LIST`,
  POST_SET_SAVE_COUNT: `${POSTS_SET}_SAVE_COUNT_LIST`,
  POST_SET_LIKED: `${POSTS_SET}_LIKED_LIST`,
  POST_SET_LIKE_COUNT: `${POSTS_SET}_LIKE_COUNT_LIST`,
  CUSTOM_FIELDS_PARAMS: `${POSTS_SET}_CUSTOM_FIELDS_PARAMS`,
  VIEWED_POST_IDS: `${POSTS_SET}_VIEWED_POST_IDS`,
  IDEAS_QUERY_PARAMS: `${POSTS_SET}_IDEAS_QUERY_PARAMS`,
  UNREAD_POSTS_COUNT: `${POSTS_SET}_UNREAD_POSTS_COUNT`,
  DISMISSED_USER_IDS: `${POSTS_SET}_DISMISSED_USER_IDS`,
  UPDATE_DISMISSED_USER_IDS: `UPDATE_${POSTS_SET}_DISMISSED_USER_IDS`,
  FILTER_RESET: `UPDATE_${POSTS_SET}_FILTER_RESET`
};

const listPostsSetSavedKeys = {
  PAGE_PARAMS_DEFAULT: `${POSTS_SET}_SAVED_PAGE_PARAMS_DEFAULT`,
  PAGE_PARAMS: `${POSTS_SET}_SAVED_PAGE_PARAMS`,
  IS_PAGINATING: `${POSTS_SET}_SAVED_IS_PAGINATING`,
  PAGE_INFO: `${POSTS_SET}_SAVED_PAGE_INFO`,
  LIST_POSTS_SET: `${POSTS_SET}_SAVED_LIST`,
  UPDATE_LIST_POSTS_SET_ITEM: `UPDATE_LIST_${POSTS_SET}_SAVED_ITEM`,
  REMOVE_LIST_POSTS_SET_ITEM: `REMOVE_LIST_${POSTS_SET}_SAVED_ITEM`
};

const listPostsSetLikedKeys = {
  PAGE_PARAMS_DEFAULT: `${POSTS_SET}_LIKED_PAGE_PARAMS_DEFAULT`,
  PAGE_PARAMS: `${POSTS_SET}_LIKED_PAGE_PARAMS`,
  IS_PAGINATING: `${POSTS_SET}_LIKED_IS_PAGINATING`,
  PAGE_INFO: `${POSTS_SET}_LIKED_PAGE_INFO`,
  LIST_POSTS_SET: `${POSTS_SET}_LIKED_LIST`,
  UPDATE_LIST_POSTS_SET_ITEM: `UPDATE_LIST_${POSTS_SET}_LIKED_ITEM`,
  REMOVE_LIST_POSTS_SET_ITEM: `REMOVE_LIST_${POSTS_SET}_LIKED_ITEM`
};

const listPostsSetHomeKeys = {
  SEARCH_PARAMS: `${POSTS_SET}_HOME_INDEX_SEARCH_PARAMS`,
  FILTER_PARAMS: `${POSTS_SET}_HOME_FILTER_PARAMS`,
  SORT_PARAMS: `${POSTS_SET}_HOME_SORT_PARAMS`,
  PAGE_PARAMS: `${POSTS_SET}_HOME_PAGE_PARAMS`,
  SEARCH_PARAMS_DEFAULT: `${POSTS_SET}_HOME_SEARCH_PARAMS_DEFAULT`,
  FILTER_PARAMS_DEFAULT: `${POSTS_SET}_HOME_FILTER_PARAMS_DEFAULT`,
  SORT_PARAMS_DEFAULT: `${POSTS_SET}_HOME_SORT_PARAMS_DEFAULT`,
  PAGE_PARAMS_DEFAULT: `${POSTS_SET}_HOME_PAGE_PARAMS_DEFAULT`,
  IS_PAGINATING: `${POSTS_SET}_HOME_IS_PAGINATING`,
  PAGE_INFO: `${POSTS_SET}_HOME_PAGE_INFO`,
  LIST_POSTS_SET: `${POSTS_SET}_HOME_LIST`
};

const listPostsSetRecentKeys = {
  SEARCH_PARAMS: `${POSTS_SET}_RECENT_INDEX_SEARCH_PARAMS`,
  FILTER_PARAMS: `${POSTS_SET}_RECENT_FILTER_PARAMS`,
  SORT_PARAMS: `${POSTS_SET}_RECENT_SORT_PARAMS`,
  PAGE_PARAMS: `${POSTS_SET}_RECENT_PAGE_PARAMS`,
  SEARCH_PARAMS_DEFAULT: `${POSTS_SET}_RECENT_SEARCH_PARAMS_DEFAULT`,
  FILTER_PARAMS_DEFAULT: `${POSTS_SET}_RECENT_FILTER_PARAMS_DEFAULT`,
  SORT_PARAMS_DEFAULT: `${POSTS_SET}_RECENT_SORT_PARAMS_DEFAULT`,
  PAGE_PARAMS_DEFAULT: `${POSTS_SET}_RECENT_PAGE_PARAMS_DEFAULT`,
  IS_PAGINATING: `${POSTS_SET}_RECENT_IS_PAGINATING`,
  PAGE_INFO: `${POSTS_SET}_RECENT_PAGE_INFO`,
  LIST_POSTS_SET: `${POSTS_SET}_RECENT_LIST`
};

const listPostsSetRelatedPostsKeys = {
  SEARCH_PARAMS: `${POSTS_SET}_RELATED_POSTS_INDEX_SEARCH_PARAMS`,
  FILTER_PARAMS: `${POSTS_SET}_RELATED_POSTS_FILTER_PARAMS`,
  SORT_PARAMS: `${POSTS_SET}_RELATED_POSTS_SORT_PARAMS`,
  PAGE_PARAMS: `${POSTS_SET}_RELATED_POSTS_PAGE_PARAMS`,
  SEARCH_PARAMS_DEFAULT: `${POSTS_SET}_RELATED_POSTS_SEARCH_PARAMS_DEFAULT`,
  FILTER_PARAMS_DEFAULT: `${POSTS_SET}_RELATED_POSTS_FILTER_PARAMS_DEFAULT`,
  SORT_PARAMS_DEFAULT: `${POSTS_SET}_RELATED_POSTS_SORT_PARAMS_DEFAULT`,
  PAGE_PARAMS_DEFAULT: `${POSTS_SET}_RELATED_POSTS_PAGE_PARAMS_DEFAULT`,
  IS_PAGINATING: `${POSTS_SET}_RELATED_POSTS_IS_PAGINATING`,
  PAGE_INFO: `${POSTS_SET}_RELATED_POSTS_PAGE_INFO`,
  LIST_POSTS_SET: `${POSTS_SET}_RELATED_POSTS_LIST`,
  IS_AUTHOR_POSTS: `${POSTS_SET}_IS_AUTHOR_POSTS`
};

const TRENDING_USERS = 'TRENDING_USERS';

const trendingUsersKeys = {
  PAGE_PARAMS: `${TRENDING_USERS}_PAGE_PARAMS`,
  PAGE_PARAMS_DEFAULT: `${TRENDING_USERS}_PAGE_PARAMS_DEFAULT`,
  IS_PAGINATING: `${TRENDING_USERS}_IS_PAGINATING`,
  PAGE_INFO: `${TRENDING_USERS}_PAGE_INFO`,
  TRENDING_USERS: `${TRENDING_USERS}_LIST`
};

const postDetailKeys = keyMirror({
  POST_SET_DETAIL: null,
  POST_SET_BREADCRUMB: null,
  POST_SET_ID: null,
  POST_SET_LIKED: null,
  POST_SET_LIKE_COUNT: null,
  POST_SET_IS_AUTHOR: null,
  POST_SET_SAVED: null,
  POST_SET_SAVE_COUNT: null,
  POST_SET_IMAGE_SAVED: null,
  POST_SET_IMAGE_SAVE_COUNT: null,
  POST_AUTHOR_RECENT_POSTS: null
});

const imageCropperKeys = keyMirror({
  IMAGE_CROPPERS: null,
  UPDATE_IMAGE_CROPPER: null,
  UPDATE_ALL_IMAGE_CROPPER_AR: null,
  DELETE_IMAGE_CROPPER: null,
  SWAP_IMAGE_CROPPER: null,
  REPLACE_IMAGE_CROPPER: null
});

const imageMarkerKeys = keyMirror({
  IMAGE_MARKERS: null,
  CACHED_MARKERS: null,
  UPDATE_CACHED_MARKERS: null,
  CURRENT_MARKER: null,
  IS_FOCUSED_THUMB: null,
  HIDE_ALL_OTHER_MARKERS: null,
  ADD_NEW_IMAGE_MARKER: null,
  UPDATE_IMAGE_MARKER: null,
  DELETE_IMAGE_MARKER: null,
  ADD_NEW_IMAGE: null,
  DELETE_IMAGE: null,
  REPLACE_IMAGE_MARKER: null,
  UPDATE_SHOPPING_OFFER_DRAFT_INFO: null
});

// --------------------
// POST::EDITORIAL
// POST::HOUSEWARM
// --------------------

const HOUSEWARM_DEFAULT_VISIBILITY = 'hidden';
const HOUSEWARM_DEFAULT_APPROVAL = 'requested';

const FEATURED_EDITORIAL_ARTICLES_INDEX_PATH = `${EDITORIAL_INDEX_PATH}/featured`;
const HOMEOWNERS_ARTICLES_INDEX_PATH = `${EDITORIAL_INDEX_PATH}/homeowners-guide`;
const STYLE_GUIDE_ARTICLES_INDEX_PATH = `${EDITORIAL_INDEX_PATH}/style-guide`;
const ORGANISATION_ARTICLES_INDEX_PATH = `${EDITORIAL_INDEX_PATH}/organising-cleaning`;
const SHOPPING_ARTICLES_INDEX_PATH = `${EDITORIAL_INDEX_PATH}/shopping`;
const LIFESTYLE_ARTICLES_INDEX_PATH = `${EDITORIAL_INDEX_PATH}/lifestyle`;
const ID_ARTICLES_INDEX_PATH = `${EDITORIAL_INDEX_PATH}/interior-designers`;

const ARTICLES_INDEX_API = '/articles';
const ARTICLE_DRAFTS_API = '/article_drafts';
const EDITORIAL_ARTICLE_TYPE = 'post_editorial';
const HOUSEWARM_ARTICLE_TYPE = 'post_housewarm';

const ARTICLE_LIST_GRID = { m: 1, t: 3, d: 3, place: 12 };

const atomKeys = keyMirror({
  BREADCRUMB: null,
  ARTICLE_DETAIL: null,
  SPLASH_ARTICLES: null,
  SHOPPING_ARTICLES: null,
  LIFESTYLE_ARTICLES: null,
  EDITORIAL_ARTICLES: null,
  HOUSEWARM_ARTICLES: null,
  STYLE_GUIDE_ARTICLES: null,
  HOMEOWNERS_ARTICLES: null,
  ORGANISATION_ARTICLES: null,
  INTERIOR_DESIGNERS_ARTICLES: null,
  FEATURED_EDITORIAL_ARTICLES: null
});

const EDITORIAL = EDITORIAL_ARTICLE_TYPE.toUpperCase();
const editorialKeys = {
  SEARCH_PARAMS: `${EDITORIAL}_SEARCH_PARAMS`,
  FILTER_PARAMS: `${EDITORIAL}_FILTER_PARAMS`,
  SORT_PARAMS: `${EDITORIAL}_SORT_PARAMS`,
  PAGE_PARAMS: `${EDITORIAL}_PAGE_PARAMS`,
  SEARCH_PARAMS_DEFAULT: `${EDITORIAL}_SEARCH_PARAMS_DEFAULT`,
  FILTER_PARAMS_DEFAULT: `${EDITORIAL}_FILTER_PARAMS_DEFAULT`,
  SORT_PARAMS_DEFAULT: `${EDITORIAL}_SORT_PARAMS_DEFAULT`,
  PAGE_PARAMS_DEFAULT: `${EDITORIAL}_PAGE_PARAMS_DEFAULT`,
  IS_PAGINATING: `${EDITORIAL}_IS_PAGINATING`,
  PAGE_INFO: `${EDITORIAL}_PAGE_INFO`,
  ARTICLES_LIST: `${EDITORIAL}_ARTICLES_LIST`,
  ACTIVE_SORTING: `${EDITORIAL}_ACTIVE_SORTING`
};

const HOUSEWARM = HOUSEWARM_ARTICLE_TYPE.toUpperCase();
const housewarmKeys = {
  SEARCH_PARAMS: `${HOUSEWARM}_SEARCH_PARAMS`,
  FILTER_PARAMS: `${HOUSEWARM}_FILTER_PARAMS`,
  SORT_PARAMS: `${HOUSEWARM}_SORT_PARAMS`,
  PAGE_PARAMS: `${HOUSEWARM}_PAGE_PARAMS`,
  SEARCH_PARAMS_DEFAULT: `${HOUSEWARM}_SEARCH_PARAMS_DEFAULT`,
  FILTER_PARAMS_DEFAULT: `${HOUSEWARM}_FILTER_PARAMS_DEFAULT`,
  SORT_PARAMS_DEFAULT: `${HOUSEWARM}_SORT_PARAMS_DEFAULT`,
  PAGE_PARAMS_DEFAULT: `${HOUSEWARM}_PAGE_PARAMS_DEFAULT`,
  IS_PAGINATING: `${HOUSEWARM}_IS_PAGINATING`,
  PAGE_INFO: `${HOUSEWARM}_PAGE_INFO`,
  ARTICLES_LIST: `${HOUSEWARM}_ARTICLES_LIST`,
  DEFAULT_HOUSEWARM_FORM: `DEFAULT_${HOUSEWARM}_FORM`,
  HOUSEWARM_FORM: `${HOUSEWARM}_FORM`,
  HAS_HOUSEWARM_DRAFT: `HAS_${HOUSEWARM}_DRAFT`,
  CHANGE_HOUSEWARM_FORM: `CHANGE_${HOUSEWARM}_FORM`,
  HOUSEWARM_DRAFTS: `${HOUSEWARM}_DRAFTS`,
  ADD_HOUSEWARM_DRAFT: `ADD_${HOUSEWARM}_DRAFT`,
  EDIT_HOUSEWARM_DRAFT: `EDIT_${HOUSEWARM}_DRAFT`,
  DELETE_HOUSEWARM_DRAFT: `DELETE_${HOUSEWARM}_DRAFT`,
  DELETE_ALL_HOUSEWARM_DRAFTS: `DELETE_ALL_${HOUSEWARM}_DRAFTS`,
  PICK_HOUSEWARM_DRAFT: `PICK_${HOUSEWARM}_DRAFT`,
  ACTIVE_HOUSEWARM_DRAFT: `ACTIVE_${HOUSEWARM}_DRAFT`,
  ACTIVE_HOUSEWARM_DRAFT_ID: `ACTIVE_${HOUSEWARM}_DRAFT_ID`,
  HOUSEWARM_CUSTOM_FIELDS: `${HOUSEWARM}_CUSTOM_FIELDS`,
  HOUSEWARM_SETTINGS: `${HOUSEWARM}_SETTINGS`,
  HOUSEWARM_FORM_ERRORS: `${HOUSEWARM}_FORM_ERRORS`,
  ADD_HOUSEWARM_FORM_ERRORS: `ADD_${HOUSEWARM}_FORM_ERRORS`,
  REMOVE_HOUSEWARM_FORM_ERRORS: `REMOVE_${HOUSEWARM}_FORM_ERRORS`
};

const DEFAULT_POSTER = {
  id: '0',
  first_name: 'HipVan Editor',
  last_name: '',
  nickname: 'hipvaneditor',
  image: '',
  description: ''
};

const SAVED_LISTING_STATE_KEY = 'saved';
const LIKED_LISTING_STATE_KEY = 'liked';
const POSTS_SET_LISTING_STATE_KEY = 'list_posts_set';

// --------------------
// COMMENTS
// --------------------

const COMMENT_SECTION_ID = 'comments';
const COMMENT_PLACEHOLDER = 'Leave your comment';
const COMMENT_POST_API = '/comments';
const COMMENT_LIST_API = '/comment_list';
const COMMENT_SUGGEST_MENTIONS_API = '/comments/suggest_mentions';

const commentKeys = keyMirror({
  COMMENT_POST: null,
  SUB_COMMENT_POST: null,
  COMMENT_LIST: null,
  SUB_COMMENT_LIST: null,
  DELETE_COMMENT: null,
  TOTAL_COMMENT: null,
  TOTAL_PAGE_COMMENT: null,
  PAGE_PARAMS_DEFAULT_COMMENT: null,
  PAGE_PARAMS_COMMENT: null,
  IS_PAGINATING_COMMENT: null,
  PAGE_COUNT_COMMENT: null,
  PAGINATING_QUERY_COMMENT: null
});

const contentFilterKeys = keyMirror({
  CURRENT_FILTERED: null,
  FILTER_OPTIONS: null,
  SUB_FILTER_OPTIONS: null
});

const ANON_CUSTOMER_SUBMIT_IDEAS_PICTURE_FORM =
  'ANON_CUSTOMER_SUBMIT_IDEAS_PICTURE_FORM';
const ANON_CUSTOMER_LIKED_PICTURE_ID = 'ANON_CUSTOMER_LIKED_PICTURE_ID';
const ANON_CUSTOMER_SAVED_PICTURE_ID = 'ANON_CUSTOMER_SAVED_PICTURE_ID';
const ANON_CUSTOMER_SUBMIT_HOUSEWARM_FORM =
  'ANON_CUSTOMER_SUBMIT_HOUSEWARM_FORM';
const CUSTOMER_SAVE_ACTION = {
  SAVE: 'save',
  UNSAVE: 'unsave'
};
const CUSTOMER_LIKE_ACTION = {
  LIKE: 'like',
  UNLIKE: 'unlike'
};
const DELAY_TOGGLE_STATE = 500;
const SUB_FILTER_KEY = 'area_of_homes';
const SORT_BY_KEY = 'sort_by';

// --------------------
// SHOPPING
// --------------------

const SHOPPING_OFFERS_API = '/shopping/offers';
const SHOPPING_SAVE_DRAFT_OFFER_API = '/shopping/offers/save_draft';
const SHOPPING_PROVIDERS_API = '/shopping/providers';
const SHOPPING_DND_KEY = 'MARKER';

const APP_SETTINGS_API = '/app_settings';
const APP_SETTINGS_BY_NAME_API = '/app_settings/by_name';
const FILTER_POSTS_FORM_API = `${APP_SETTINGS_API}/filter_posts_form`;

export {
  HELMET_CONTEXT,
  DT_FORMAT,
  NEW_FOR_MONTHS,
  ALLOW_FILE_FORMATS,
  ALLOW_FILE_ATTRIBS,
  MAX_FILE_SIZE,
  MAX_FILES,
  FILE_REJECTIONS,
  changePositionType,
  LIKE_API,
  ARTICLE_ACTION_TYPE,
  selectorKeys,
  CUSTOM_FIELDS,
  BLOG_INDEX_NAME,
  BLOG_INDEX_PATH,
  ARTICLE_INDEX_NAME,
  ARTICLE_INDEX_PATH,
  EDITORIAL_INDEX_ITEM,
  EDITORIAL_INDEX_NAME,
  EDITORIAL_INDEX_PATH,
  HOUSEWARM_INDEX_ITEM,
  HOUSEWARM_INDEX_NAME,
  HOUSEWARM_INDEX_PATH,
  HOUSEWARM_NEW_PATH,
  PICTURES_INDEX_ITEM,
  PICTURES_INDEX_NAME,
  PICTURES_INDEX_PATH,
  PICTURES_NEW_PATH,
  PICTURES,
  POSTS_SET,
  HOME_TOURS_API,
  FOLLOWING_API,
  HOME_PROFESSIONAL_API,
  RESET_FOLLOWING_POSTS_COUNT_API,
  FOLLOWING_POST_NEW_TYPE,
  POST_CUSTOM_FIELDS_API,
  PICTURES_POST_API,
  PROFILE_PICTURES_INDEX_API,
  POSTS_SET_API,
  HASHTAG_SUGGESTIONS_API,
  USERS_SUGGEST_FOLLOWEES,
  USERS_PRIVATE_SUGGEST_FOLLOWEES,
  USERS_DISMISS_FOLLOWEES,
  DISMISSED_USER_IDS_KEY,
  SUGGEST_FOLLOWEES_LIMIT_PAGE,
  POST_PICTURES_KEY,
  SET_POST_PICTURES_KEY,
  MOB_GUTTER,
  MOB_COLUMNS_COUNT,
  MOB_SCROLL_THRESHOLD,
  DESK_SCROLL_THRESHOLD,
  INDEX_CONTENT_PER_ROW,
  POSTS_LIST_GRID,
  HOME_POST_GRID,
  picturesKeys,
  listPostsSetKeys,
  listPostsSetSavedKeys,
  listPostsSetLikedKeys,
  listPostsSetHomeKeys,
  listPostsSetRecentKeys,
  listPostsSetRelatedPostsKeys,
  trendingUsersKeys,
  postDetailKeys,
  imageCropperKeys,
  imageMarkerKeys,
  HOUSEWARM_DEFAULT_VISIBILITY,
  HOUSEWARM_DEFAULT_APPROVAL,
  FEATURED_EDITORIAL_ARTICLES_INDEX_PATH,
  HOMEOWNERS_ARTICLES_INDEX_PATH,
  STYLE_GUIDE_ARTICLES_INDEX_PATH,
  ORGANISATION_ARTICLES_INDEX_PATH,
  SHOPPING_ARTICLES_INDEX_PATH,
  LIFESTYLE_ARTICLES_INDEX_PATH,
  ID_ARTICLES_INDEX_PATH,
  POST_HOUSEWARM_KEY,
  POST_EDITORIAL_KEY,
  ARTICLES_INDEX_API,
  ARTICLE_DRAFTS_API,
  ARTICLE_LIST_GRID,
  EDITORIAL_ARTICLE_TYPE,
  HOUSEWARM_ARTICLE_TYPE,
  atomKeys,
  editorialKeys,
  housewarmKeys,
  blogRoutesKeys,
  DEFAULT_POSTER,
  ANON_CUSTOMER_LIKED_PICTURE_ID,
  ANON_CUSTOMER_SAVED_PICTURE_ID,
  ANON_CUSTOMER_SUBMIT_IDEAS_PICTURE_FORM,
  ANON_CUSTOMER_SUBMIT_HOUSEWARM_FORM,
  DELAY_TOGGLE_STATE,
  SUB_FILTER_KEY,
  SORT_BY_KEY,
  commentKeys,
  contentFilterKeys,
  COMMENT_PLACEHOLDER,
  COMMENT_POST_API,
  COMMENT_LIST_API,
  contentSortFeatured,
  contentSortPopular,
  contentSortRecent,
  contentSortHouseTours,
  contentSortFollowing,
  contentSortProfessional,
  contentSortOptions,
  contentSortOrder,
  COMMENT_SUGGEST_MENTIONS_API,
  sortValueFirstVisit,
  sortValueAfterPostCreated,
  BOOKMARKS_API,
  COMPLAINTS_API,
  CUSTOMER_SAVE_ACTION,
  CUSTOMER_LIKE_ACTION,
  DEFAULT_SLATE_VALUE,
  CONTEST_LIST_GRID,
  CONTEST_STATUS,
  CONTESTS_INDEX_ITEM,
  CONTESTS_INDEX_NAME,
  CONTESTS_INDEX_PATH,
  HASHTAG_INDEX_ITEM,
  HASHTAG_INDEX_NAME,
  HASHTAG_INDEX_PATH,
  OFFER_INDEX_ITEM,
  OFFER_INDEX_NAME,
  OFFER_INDEX_PATH,
  COMMENT_SECTION_ID,
  CONTESTS_API,
  contestsKeys,
  contestListKeys,
  SHOPPING_OFFERS_API,
  SHOPPING_SAVE_DRAFT_OFFER_API,
  SHOPPING_PROVIDERS_API,
  SHOPPING_DND_KEY,
  FILTER_POSTS_FORM_API,
  APP_SETTINGS_BY_NAME_API,
  CONTENT_TYPE,
  POST_FORMATS,
  CONTENT_TYPE_LINK_DATA,
  DRAFT_POSTS_API,
  SAVED_LISTING_STATE_KEY,
  LIKED_LISTING_STATE_KEY,
  POSTS_SET_LISTING_STATE_KEY
};
